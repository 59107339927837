body {
  background-color: #53cbf0;
}

textarea {
  resize: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding-left: 5px;
}

label {
  color: rgb(108, 108, 108);
}

button {
  height: 25px;
  padding: 0 8px !important;
}

.hide {
  display: none;
}

.btn-signin {
  height: 50px;
  width: 200px;
}

.heading {
  height: 60px;
  background-color: #5a9bfd;
  font-family: 'Lato', sans-serif;
  color: #ffffff;
  overflow: hidden;
}

.roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

.filter-label-off {
  color: rgb(0, 252, 76);
}

.filter-label-on {
  color: rgb(164, 17, 17);
}

.idismiss-title-margins {
  margin-top: 10px;
  margin-left: 8px;
  margin-bottom: 0;
}

.slogan-margins {
  margin-left: 8px;
  margin-bottom: 0;
  max-height: 19px;
}

.person-icon {
  font-size: 2rem;
  margin-bottom: 5px;
}

.email-margin {
  margin-top: 10px;
  margin-left: 0.5rem;
  margin-bottom: 10px;
}

.main {
  height: 100vh;
  min-width: 1280px;
}

.left-sidebar-top {
  margin-top: 60px;
  height: 120px;
}

.left-sidebar-center {
  height: 10px;
}

.left-sidebar-bottom {
  margin-top: 5px;
  height: 700px;
  margin-bottom: 0px;
}

.side-columns {
  height: 670px;
  overflow: auto;
}

.side-columns::-webkit-scrollbar {
  display: none;
}

.main-column {
  margin-top: 60px;
  font-family: 'Open Sans', sans-serif;
  height: 834px;
  overflow: auto;
}

.main-column::-webkit-scrollbar {
  display: none;
}

.card {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 0.8rem;
  background-color: lightgreen;
}

.card-right {
  background-color: moccasin;
}

.filter-radios {
  width: 10px;
  height: 10px;
}

.filter-inputs {
  height: 24px;
  font-size: 0.9rem;
}

.code-margin {
  margin-bottom: 19px;
}
